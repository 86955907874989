// src/components/OTPVerification.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OtpVerification.css';
import axiosInstance from './config/axios';

const OTPVerification = ({ phoneNumber, onVerificationSuccess, onCancel }) => {
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [timer, setTimer] = useState(30);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const inputs = Array(6).fill(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to next input
        if (element.value && element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp);

            // Move to previous input
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axiosInstance.post('/api/verify-otp/', {
                phone_number: phoneNumber,
                otp: otp.join('')
            });

            if (response.data.verified) {
                onVerificationSuccess(response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Verification failed');
        } finally {
            setLoading(false);
        }
    };

    const handleResendOTP = async () => {
        if (timer > 0) return;

        try {
            setLoading(true);
            setError('');
            await axiosInstance.post('/api/send-otp/', {
                phone_number: phoneNumber
            });
            setTimer(30);
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to resend OTP');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="otp-container">
            <h2>Two-Factor Authentication</h2>
            <p>Enter the verification code sent to {phoneNumber}</p>
            
            <form onSubmit={handleSubmit}>
                <div className="otp-inputs">
                    {inputs.map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={otp[index]}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            disabled={loading}
                        />
                    ))}
                </div>

                {error && <div className="error-message">{error}</div>}

                <div className="buttons">
                    <button 
                        type="submit" 
                        className="verify-button"
                        disabled={loading || otp.join('').length !== 6}
                    >
                        {loading ? 'Verifying...' : 'Verify'}
                    </button>
                    
                    <button 
                        type="button"
                        className="resend-button"
                        onClick={handleResendOTP}
                        disabled={timer > 0 || loading}
                    >
                        {timer > 0 ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                    </button>
                </div>
            </form>

            <button 
                type="button" 
                className="cancel-button"
                onClick={onCancel}
                disabled={loading}
            >
                Cancel
            </button>
        </div>
    );
};

export default OTPVerification;