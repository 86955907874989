import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { useListings } from './ListingsContext';
import styles from './styles/ListingCard.module.css';
import axiosInstance from './config/axios';

const formatValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(', ') : 'Not Provided';
  }
  if (typeof value === 'object' && value !== null) {
    if ('length' in value && 'width' in value && 'height' in value) {
      return `${value.length || 0} × ${value.width || 0} × ${value.height || 0} inches`;
    }
    return 'Not Provided';
  }
  return value || 'Not Provided';
};

const DetailRow = ({ label, value }) => (
  <div className="grid grid-cols-2 gap-2 mb-2">
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{formatValue(value)}</span>
  </div>
);

const ListingCard = ({ listing, onBidClick }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const { updateListing } = useListings();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('access');
        if (!token) {
          console.log('No token found');
          return;
        }
        const response = await axiosInstance.get('/api/user-details/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        console.log('User details:', response.data);
        setUserDetails(response.data);
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
  }, []);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      try {
        const token = localStorage.getItem('access');
        await axiosInstance.delete(`/api/gpu-listings/${listing.id}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        // Refresh listings after delete
        window.location.reload();
      } catch (error) {
        console.error('Error deleting listing:', error);
        alert('Failed to delete listing. Please try again.');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/edit-listing/${listing.id}`);
  };

  const handleViewDetails = () => {
    navigate(`/detail/${listing.id}`, { state: { listing } });
  };

  const handleBidClick = () => {
    onBidClick(listing);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const CompactView = () => (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <h3 className="text-xl font-bold mb-4">{listing.gpu_type}</h3>
        <DetailRow label="Server Name" value={listing.server_name} />
        <DetailRow label="Condition" value={listing.condition} />
        <DetailRow label="Chassis" value={listing.chassis} />
        <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
      </div>
      <div className={styles.cardFooter}>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-sm opacity-80 mb-1">Time Left: {listing.time_left}</div>
            <div className="font-medium">Ask Price: ${listing.ask_price}</div>
            <div className="text-sm mt-1">
              {listing.current_highest_bid ? (
                <>
                  Highest Bid: ${listing.current_highest_bid}
                  {listing.highest_bidder_username && (
                    <span className="ml-1">by {listing.highest_bidder_username}</span>
                  )}
                </>
              ) : (
                'No bids yet'
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <button 
              onClick={handleBidClick}
              className={`${styles.button} ${styles.primaryButton}`}
            >
              Bid
            </button>
            <button 
              onClick={handleViewDetails}
              className={styles.button}
            >
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const ExpandedView = () => (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <h3 className="text-xl font-bold mb-4">{listing.gpu_type}</h3>
        <div className="flex flex-col space-y-2">
          <DetailRow label="Server Name" value={listing.server_name} />
          <DetailRow label="Condition" value={listing.condition} />
          <DetailRow label="Warranty" value={listing.warranty ? `${listing.warranty} years` : 'Not Provided'} />
          <DetailRow label="Chassis" value={listing.chassis} />
          <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
          <DetailRow label="Number of Nodes" value={listing.number_of_nodes} />
          <DetailRow label="GPUs per Node" value={listing.number_of_gpus_per_node} />
          <DetailRow label="Processor Type" value={listing.processor_type} />
          <DetailRow label="Memory" value={`${listing.memory} GB`} />
          <DetailRow label="Operating Systems" value={listing.operating_systems} />
          <DetailRow label="Ask Price" value={`$${listing.ask_price}`} />
          <DetailRow 
            label="Current Highest Bid" 
            value={
              listing.current_highest_bid 
                ? `$${listing.current_highest_bid}${listing.highest_bidder_username ? ` by ${listing.highest_bidder_username}` : ''}`
                : 'No bids yet'
            } 
          />
        </div>
      </div>
      <div className={styles.cardFooter}>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-sm opacity-80 mb-1">Time Left: {listing.time_left}</div>
            {userDetails?.is_superuser && (
              <div className="flex gap-2 mt-3">
                <button 
                  onClick={handleEdit}
                  className={`${styles.button} ${styles.primaryButton}`}
                >
                  Edit
                </button>
                <button 
                  onClick={handleDelete}
                  className={`${styles.button} ${styles.deleteButton}`}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          <div className="flex gap-2">
            <button 
              onClick={handleBidClick}
              className={`${styles.button} ${styles.primaryButton}`}
            >
              Bid
            </button>
            <button 
              onClick={handleViewDetails}
              className={styles.button}
            >
              Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
      className="transition-all duration-300"
    >
      {isHovered ? <ExpandedView /> : <CompactView />}
    </div>
  );
};

export default ListingCard;