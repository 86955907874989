// src/components/MyListingsPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles/MyListingPage.modules.css';
import axiosInstance from './config/axios';

const MyListingsPage = ({ username }) => {
  const [myListings, setMyListings] = useState([]);
  const [myBids, setMyBids] = useState([]);
  const [error, setError] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isListing, setIsListing] = useState(true);
  const [updateForm, setUpdateForm] = useState({});
  const [userPurpose, setUserPurpose] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserDetails();
    fetchMyListings();
    fetchMyBids();
  }, []);

  const fetchUserDetails = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get('/api/user-details/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setUserPurpose(response.data.purpose);
    } catch (err) {
      setError('Failed to fetch user details');
    }
  };

  const fetchMyListings = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get('/api/gpu-listings/my_listings/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      setMyListings(response.data);
    } catch (err) {
      setError('Failed to fetch your listings');
    }
  };

  const fetchMyBids = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get('/api/bids/', {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      
      const bidsWithListings = await Promise.all(response.data.map(async (bid) => {
        try {
          const listingResponse = await axiosInstance.get(`/api/gpu-listings/${bid.listing}/`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
          return { ...bid, listing: listingResponse.data };
        } catch (error) {
          // If listing not found, return bid with placeholder listing data
          return {
            ...bid,
            listing: {
              gpu_type: 'Listing no longer available',
              current_highest_bid: 'N/A'
            }
          };
        }
      }));
      setMyBids(bidsWithListings);
    } catch (err) {
      setError('Failed to fetch your bids');
    }
  };

  const handleDelete = async (id, isListing) => {
    try {
      const token = localStorage.getItem('access');
      const endpoint = isListing ? `gpu-listings` : 'bids';
      await axiosInstance.delete(`/api/${endpoint}/${id}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (isListing) {
        setMyListings(prev => prev.filter(item => item.id !== id));
      } else {
        setMyBids(prev => prev.filter(item => item.id !== id));
      }
      // Refresh data after deletion
      fetchMyListings();
      fetchMyBids();
    } catch (err) {
      setError(`Failed to delete ${isListing ? 'listing' : 'bid'}`);
    }
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('access');
      const endpoint = isListing ? `gpu-listings` : 'bids';
  
      // For bids, validate against current highest bid
      if (!isListing && selectedItem) {
        const listing = selectedItem.listing;
        if (parseFloat(updateForm.amount) <= parseFloat(listing.current_highest_bid)) {
          setError('Bid cannot be less than or equal to the current highest bid');
          return;
        }
      }
  
      const response = await axiosInstance.patch(
        `/api/${endpoint}/${selectedItem.id}/`,
        updateForm,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
      
      if (!isListing) {
        // Update the bid and listing in the frontend state
        setMyBids(prev => prev.map(bid => 
          bid.id === selectedItem.id 
            ? {
                ...bid, 
                created_at: new Date().toISOString(), 
                amount: updateForm.amount,
                listing: {
                  ...bid.listing,
                  current_highest_bid: updateForm.amount
                }
              }
            : bid
        ));
      } else {
        fetchMyListings();
      }
  
      setShowUpdateModal(false);
      setError('');
    } catch (err) {
      setError(`Failed to update ${isListing ? 'listing' : 'bid'}: ${err.response?.data?.detail || err.message}`);
    }
  };

  const openUpdateModal = (item, isListingItem) => {
    setSelectedItem(item);
    setIsListing(isListingItem);
    setUpdateForm(isListingItem ? {
      ask_price: item.ask_price,
      additional_notes: item.additional_notes
    } : {
      amount: item.amount
    });
    setShowUpdateModal(true);
  };

  const sortedBids = [...myBids].sort((a, b) => {
    // Check if either listing is no longer available
    const aExpired = a.listing?.gpu_type === 'Listing no longer available';
    const bExpired = b.listing?.gpu_type === 'Listing no longer available';
    
    // Put active bids first
    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;
    
    // For bids with same status (both active or both expired),
    // sort by creation date (newest first)
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const sortedListings = [...myListings].sort((a, b) => {
    // Check if either listing is expired
    const aExpired = a.time_left === 'Bidding ended';
    const bExpired = b.time_left === 'Bidding ended';
    
    // Put active listings first
    if (aExpired && !bExpired) return 1;
    if (!aExpired && bExpired) return -1;
    
    // For listings with same status (both active or both expired),
    // sort by time_left
    return new Date(b.created_at) - new Date(a.created_at);
  });
  


  return (
    <Container className="py-4">
      <h1 className="mb-4">My Dashboard</h1>
      
      <Row>
      {userPurpose === 'seller' && (
  <Col md={6}>
    <Card className="mb-4">
      <Card.Header>
        <h2>My Listings</h2>
      </Card.Header>
      <Card.Body>
        {sortedListings.map(listing => (
          <Card key={listing.id} className="mb-3">
            <Card.Body>
              <Card.Title>{listing.gpu_type}</Card.Title>
              <Card.Text>
                Ask Price: ${listing.ask_price}<br />
                Current Highest Bid: {listing.current_highest_bid ? `$${listing.current_highest_bid}` : 'No bids yet'}<br />
                Time Left: {listing.time_left}
              </Card.Text>
              {listing.time_left !== 'Bidding ended' && (
                <>
                  <Button 
                    variant="primary" 
                    className="me-2"
                    onClick={() => openUpdateModal(listing, true)}
                  >
                    Update
                  </Button>
                  <Button 
                    variant="danger"
                    onClick={() => handleDelete(listing.id, true)}
                  >
                    Delete
                  </Button>
                </>
                  )}
                </Card.Body>
              </Card>
                ))}
              </Card.Body>
            </Card>
            </Col>
          )}
        
        <Col md={6}>
          <Card>
            <Card.Header>
              <h2>My Bids</h2>
            </Card.Header>
            <Card.Body>
            {sortedBids.map(bid => (
              <Card key={bid.id} className="">
                <Card.Body>
                  <Card.Title>
                    Bid on {bid.listing?.gpu_type || 'Unavailable Listing'}
                  </Card.Title>
                  
                  <Card.Text>
                    Your Bid: ${bid.amount}<br />
                    Current Highest Bid: {bid.listing?.current_highest_bid ? `$${bid.listing.current_highest_bid}` : 'N/A'} <br/>
                    <div className="mt-2">
                      <i>Bid placed at {new Date(bid.created_at).toLocaleString()}</i>
                    </div>
                  </Card.Text>
                  
                  {bid.listing?.gpu_type !== 'Listing no longer available' && bid.listing?.time_left !== 'Expired' && (
                    <>
                      <Button 
                        variant="primary" 
                        className="me-2"
                        onClick={() => openUpdateModal(bid, false)}
                      >
                        Update
                      </Button>
                      <Button 
                        variant="danger"
                        onClick={() => handleDelete(bid.id, false)}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Update Modal */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update {isListing ? 'Listing' : 'Bid'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isListing ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Ask Price</Form.Label>
                  <Form.Control
                    type="number"
                    value={updateForm.ask_price || ''}
                    onChange={(e) => setUpdateForm({...updateForm, ask_price: e.target.value})}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Additional Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={updateForm.additional_notes || ''}
                    onChange={(e) => setUpdateForm({...updateForm, additional_notes: e.target.value})}
                  />
                </Form.Group>
              </>
            ) : (
              <Form.Group className="mb-3">
                <Form.Label>Bid Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={updateForm.amount || ''}
                  onChange={(e) => setUpdateForm({...updateForm, amount: e.target.value})}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUpdateModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {error && (
        <Modal show={!!error} onHide={() => setError('')}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-danger">{error}</div>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default MyListingsPage;