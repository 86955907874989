import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CountrySelect from './CountrySelect';
import debounce from 'lodash/debounce';
import countryList from 'react-select-country-list';
import styles from './styles/Auth.module.css';
import axiosInstance from './config/axios';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    phone_number: '',
    country_code: '+1', // Default country code
    company_name: '',
    street_address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    purpose: 'buyer',
    enable_two_factor: false,
    newsletter_preference: false,
  });

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('US');
  const navigate = useNavigate();

  useEffect(() => {
    const countryName = countryList().getLabel(selectedCountry);
    setFormData(prevData => ({
      ...prevData,
      country: countryName,
    }));
  }, [selectedCountry]);

  const handleInvalidInput = (e) => {
    if (e.target.name === 'phone_number') {
      e.target.setCustomValidity('Phone number must contain only numbers');
    } else if (e.target.name === 'zip_code') {
      e.target.setCustomValidity('Zip code must contain only numbers');
    }
  };

  const handleInput = (e) => {
    e.target.setCustomValidity('');
  };

  const handleCountryCodeChange = (value, country) => {
    const countryCode = country.countryCode.toUpperCase();
    const countryName = countryList().getLabel(countryCode);
    
    const formattedCountryCode = value.startsWith('+') ? value : '+' + value;
    setFormData(prevData => ({
      ...prevData,
      country_code: formattedCountryCode,
    }));
    
    setSelectedCountry(countryCode);
  };

  const fetchLocationData = useCallback(async (zipCode) => {
    if (zipCode.length < 5) return;

    setIsLoading(true);
    setErrors(prevErrors => ({ ...prevErrors, zip_code: '' }));
    try {
      const response = await axios.get(`https://api.zippopotam.us/${selectedCountry}/${zipCode}`);
      const data = response.data;
      
      if (data && data.places && data.places[0]) {
        const place = data.places[0];
        setFormData(prevData => ({
          ...prevData,
          city: place['place name'],
          state: place['state'],
          country: data.country,
        }));
      }
    } catch (error) {
      console.error('Error fetching location data:', error);
      setErrors(prevErrors => ({ ...prevErrors, zip_code: 'Invalid or not found zip code' }));
      setFormData(prevData => ({
        ...prevData,
        city: '',
        state: '',
      }));
    } finally {
      setIsLoading(false);
    }
  }, [selectedCountry]);

  const debouncedFetchLocationData = useCallback(
    debounce((zipCode) => fetchLocationData(zipCode), 500),
    [fetchLocationData]
  );

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'zip_code') {
      if (value.length === 0) {
        setFormData(prevData => ({
          ...prevData,
          city: '',
          state: '',
        }));
        setErrors(prevErrors => ({ ...prevErrors, zip_code: '' }));
      } else {
        debouncedFetchLocationData(value);
      }
    }
  };

  const updateCountryFromCode = (countryCode) => {
    const countryName = countryList().getLabel(countryCode);
    const countries = countryList().getData();
    const country = countries.find(c => c.value === countryCode);
    
    if (country) {
      setSelectedCountry(countryCode);
      setFormData(prevData => ({
        ...prevData,
        country: countryName,
        city: '',
        state: '',
        zip_code: '',
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    Object.keys(formData).forEach(key => {
      if (!formData[key] && typeof formData[key] !== 'boolean') {
        newErrors[key] = 'This field is required';
      }
    });

    if (!/^\d+$/.test(formData.phone_number)) {
      newErrors.phone_number = 'Phone number must contain only numbers';
    }

    if (!/^\d+$/.test(formData.zip_code)) {
      newErrors.zip_code = 'Zip code must contain only numbers';
    }

    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = "Passwords don't match";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setErrors({});

    try {
      const response = await axiosInstance.post('/api/register/', formData);
      navigate('/login');
      setMessage('Registration successful');
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
        
        if (error.response.data.username) {
          setMessage('Registration failed: ' + error.response.data.username[0]);
        } else if (error.response.data.email) {
          setMessage('Registration failed: ' + error.response.data.email[0]);
        } else if (error.response.data.phone_number) {
          setMessage('Registration failed: ' + error.response.data.phone_number[0]);
        } else {
          setMessage('Registration failed: ' + JSON.stringify(error.response.data));
        }
      } else {
        setMessage('Registration failed: ' + error.message);
      }
    }
  };

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.title}>Register</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.username && <p className={styles.error}>{errors.username}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.password && <p className={styles.error}>{errors.password}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Confirm Password</label>
          <input
            type="password"
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.confirm_password && <p className={styles.error}>{errors.confirm_password}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Phone Number</label>
          <div className={styles.phoneGroup}>
            <PhoneInput
              country={selectedCountry.toLowerCase()}
              value={formData.country_code}
              onChange={handleCountryCodeChange}
              inputProps={{
                name: 'country_code',
                required: true,
                className: `${styles.input} ${styles.countryCodeInput}`
              }}
              containerClass={styles.phoneInputContainer}
              buttonClass={styles.phoneInputButton}
              inputStyle={{ width: '90px', paddingLeft: '45px' }}
            />
            <input
              type="tel"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              pattern="[0-9]*"
              inputMode="numeric"
              onInvalid={handleInvalidInput}
              onInput={handleInput}
              required
              className={`${styles.input} ${styles.phoneNumberInput}`}
              placeholder="Enter phone number"
            />
          </div>
          {errors.country_code && <p className={styles.error}>{errors.country_code}</p>}
          {errors.phone_number && <p className={styles.error}>{errors.phone_number}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Company Name</label>
          <input
            type="text"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.company_name && <p className={styles.error}>{errors.company_name}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Country</label>
          <CountrySelect
            value={selectedCountry}
            onChange={(code) => {
              updateCountryFromCode(code);
            }}
            className={styles.select}
          />
          {errors.country && <p className={styles.error}>{errors.country}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Street Address</label>
          <input
            type="text"
            name="street_address"
            value={formData.street_address}
            onChange={handleChange}
            required
            className={styles.input}
          />
          {errors.street_address && <p className={styles.error}>{errors.street_address}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Zip Code</label>
          <input
            type="text"
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            pattern="[0-9]*"
            inputMode="numeric"
            onInvalid={handleInvalidInput}
            onInput={handleInput}
            required
            className={styles.input}
          />
          {errors.zip_code && <p className={styles.error}>{errors.zip_code}</p>}
          {isLoading && <p className={styles.loading}>Loading...</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            disabled={isLoading}
            className={styles.input}
          />
          {errors.city && <p className={styles.error}>{errors.city}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>State</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
            disabled={isLoading}
            className={styles.input}
          />
          {errors.state && <p className={styles.error}>{errors.state}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Purpose</label>
          <select
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
            className={styles.select}
          >
            <option value="buyer">Buyer</option>
            <option value="seller">Seller</option>
          </select>
          {errors.purpose && <p className={styles.error}>{errors.purpose}</p>}
        </div>

        {/*<div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            name="enable_two_factor"
            checked={formData.enable_two_factor}
            onChange={handleChange}
            className={styles.checkbox}
          />
          <label className={styles.checkboxLabel}>Enable Two-Factor Authentication</label>
        </div> */}

        <div className={styles.checkboxGroup}>
          <input
            type="checkbox"
            name="newsletter_preference"
            checked={formData.newsletter_preference}
            onChange={handleChange}
            className={styles.checkbox}
          />
          <label className={styles.checkboxLabel}>Subscribe to Newsletter & Market Updates</label>
        </div>

        <button type="submit" className={styles.button}>Register</button>
        
        <p className={styles.message}>
          Already have an account? <a href="/login" className={styles.link}>Login</a>
        </p>
      </form>
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default Register;