// EditListing.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Form, Button, Alert, Row, Col, Dropdown } from 'react-bootstrap';
import styles from './styles/EditListing.module.css';
import axiosInstance from './config/axios';
const EditListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    server_name: '',
    state: '',
    country: '',
    ask_price: '',
    duration_of_bid: '',
    condition: '',
    gpu_type: '',
    number_of_nodes: '',
    number_of_gpus_per_node: '',
    minimum_order_quantity: '',
    processor_type: '',
    memory: '',
    operating_systems: [],
    motherboard: '',
    chassis: '',
    chassis_form_factor: '',
    dimensions: { length: '', width: '', height: '' },
    net_weight: '',
    gross_weight: '',
    system_cooling: '',
    cpu_gpu_interconnect: '',
    gpu_gpu_interconnect: '',
    additional_notes: ''
  });

  const osOptions = [
    "Ubuntu (Ubuntu 22.04 LTS and Ubuntu 20.04)",
    "Debian (various versions including Debian 12, 11, 10, 9)",
    "Red Hat Enterprise Linux (RHEL) (versions 9, 8, 7)",
    "Rocky Linux (versions 9, 8)",
    "CentOS"
  ];

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const token = localStorage.getItem('access');
        const response = await axiosInstance.get(`/api/gpu-listings/${id}/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        const dimensionsObj = response.data.dimensions ? {
          length: response.data.dimensions[0] || '',
          width: response.data.dimensions[1] || '',
          height: response.data.dimensions[2] || ''
        } : { length: '', width: '', height: '' };

        setFormData({
          ...response.data,
          dimensions: dimensionsObj,
          operating_systems: Array.isArray(response.data.operating_systems) 
            ? response.data.operating_systems 
            : []
        });
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch listing details');
        setLoading(false);
      }
    };

    fetchListing();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (name === 'length' || name === 'width' || name === 'height') {
      setFormData(prevState => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          [name]: value === '' ? '' : Number(value)
        }
      }));
    } else if (type === 'number') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value === '' ? '' : Number(value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleOSToggle = (option) => {
    setFormData(prevState => ({
      ...prevState,
      operating_systems: prevState.operating_systems.includes(option)
        ? prevState.operating_systems.filter(item => item !== option)
        : [...prevState.operating_systems, option]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const token = localStorage.getItem('access');
      const submitData = {
        ...formData,
        dimensions: [
          formData.dimensions.length,
          formData.dimensions.width,
          formData.dimensions.height
        ]
      };

      await axiosInstance.put(`/api/gpu-listings/${id}/`, submitData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setSuccess('Listing updated successfully!');
      setTimeout(() => navigate('/dashboard'), 1500);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update listing');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className={styles.container}>
      <h2 className={styles.formTitle}>Edit GPU Listing</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      
      <Form onSubmit={handleSubmit}>
        <Form.Group className={styles.formGroup}>
          <Form.Label>Server Name</Form.Label>
          <Form.Control
            type="text"
            name="server_name"
            value={formData.server_name}
            onChange={handleChange}
            placeholder="Enter server name"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>State</Form.Label>
          <Form.Control
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="Enter state"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Enter country"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Ask Price</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            name="ask_price"
            value={formData.ask_price}
            onChange={handleChange}
            required
            placeholder="Enter price"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Duration of Bid (in days) {formData.duration_of_bid}</Form.Label>
          <Form.Range
            name="duration_of_bid"
            value={formData.duration_of_bid}
            onChange={handleChange}
            min="1"
            max="7"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Condition</Form.Label>
          <Form.Select
            name="condition"
            value={formData.condition}
            onChange={handleChange}
          >
            <option value="">Select Condition</option>
            <option value="New">New</option>
            <option value="Used">Used</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>GPU Type</Form.Label>
          <Form.Select
            name="gpu_type"
            value={formData.gpu_type}
            onChange={handleChange}
            required
          >
            <option value="">Select GPU Type</option>
            <option value="NVIDIA Blackwell B100">NVIDIA Blackwell B100</option>
            <option value="NVIDIA Blackwell B200">NVIDIA Blackwell B200</option>
            <option value="NVIDIA H200 SXM">NVIDIA H200 SXM</option>
            <option value="NVIDIA H200 NVL">NVIDIA H200 NVL</option>
            <option value="NVIDIA H100 PCIe">NVIDIA H100 PCIe</option>
            <option value="NVIDIA H100 SXM">NVIDIA H100 SXM</option>
            <option value="NVIDIA H100 NVL">NVIDIA H100 NVL</option>
            <option value="NVIDIA A100 SXM">NVIDIA A100 SXM</option>
            <option value="NVIDIA A100 PCIe">NVIDIA A100 PCIe</option>
            <option value="NVIDIA L40S">NVIDIA L40S</option>
            <option value="NVIDIA L40">NVIDIA L40</option>
            <option value="NVIDIA A40">NVIDIA A40</option>
            <option value="NVIDIA A6000">NVIDIA A6000</option>
            <option value="AMD Instinct MI325x">AMD Instinct MI325x</option>
            <option value="AMD Instinct MI300x">AMD Instinct MI300x</option>
            <option value="AMD Instinct MI300A APU">AMD Instinct MI300A APU</option>
            <option value="AMD Instinct MI250X">AMD Instinct MI250X</option>
            <option value="AMD Instinct MI250">AMD Instinct MI250</option>
            <option value="GROQ LPU">GROQ LPU</option>
            <option value="CEREBRAS Wafer Scale Enginer">CEREBRAS Wafer Scale Enginer</option>
            <option value="SAMBANOVA SN40L RDU">SAMBANOVA SN40L RDU</option>
            <option value="ETCHED AI Sohu">ETCHED AI Sohu</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Number of Nodes</Form.Label>
          <Form.Control
            type="number"
            name="number_of_nodes"
            value={formData.number_of_nodes}
            onChange={handleChange}
            placeholder="Enter number of nodes"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Number of GPUs per Node</Form.Label>
          <Form.Control
            type="number"
            name="number_of_gpus_per_node"
            value={formData.number_of_gpus_per_node}
            onChange={handleChange}
            placeholder="Enter number of GPUs per node"
            required
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Minimum Order Quantity</Form.Label>
          <Form.Control
            type="number"
            name="minimum_order_quantity"
            value={formData.minimum_order_quantity}
            onChange={handleChange}
            placeholder="Enter minimum order quantity"
            required
          />
        </Form.Group>
          <Form.Group className={styles.formGroup}>
          <Form.Label>Processor (CPU) Type</Form.Label>
          <Form.Control
            type="text"
            name="processor_type"
            value={formData.processor_type}
            onChange={handleChange}
            placeholder="Enter CPU type"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Memory (GB)</Form.Label>
          <Form.Control
            type="number"
            name="memory"
            value={formData.memory}
            onChange={handleChange}
            required
            placeholder="Enter memory in GB"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Operating Systems</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-os" className={styles.dropdownToggle}>
              Select Operating Systems ({formData.operating_systems.length} selected)
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.dropdownMenu}>
              {osOptions.map((option, index) => (
                <Dropdown.Item 
                  key={index} 
                  className={styles.dropdownItem}
                  as="button" 
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOSToggle(option);
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    id={`check-${index}`}
                    label={option}
                    checked={formData.operating_systems.includes(option)}
                    onChange={() => {}}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Motherboard</Form.Label>
          <Form.Control
            type="text"
            name="motherboard"
            value={formData.motherboard}
            onChange={handleChange}
            placeholder="Enter motherboard details"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Chassis</Form.Label>
          <Form.Select
            name="chassis"
            value={formData.chassis}
            onChange={handleChange}
            required
          >
            <option value="">Select Chassis</option>
            <option value="Dell">Dell</option>
            <option value="HPE">HPE</option>
            <option value="ASUS">ASUS</option>
            <option value="NVIDIA">NVIDIA</option>
            <option value="Lenovo">Lenovo</option>
            <option value="Supermicro">Supermicro</option>
            <option value="IBM">IBM</option>
            <option value="Inspur">Inspur</option>
            <option value="GIGABYTE">GIGABYTE</option>
            <option value="Atos">Atos</option>
            <option value="Penguin Computing">Penguin Computing</option>
            <option value="QCT (Quanta Cloud Technology)">QCT (Quanta Cloud Technology)</option>
            <option value="DFI">DFI</option>
            <option value="Tyan">Tyan</option>
            <option value="GROQ">GROQ</option>
            <option value="Cerebras">Cerebras</option>
            <option value="Etched">Etched</option>
            <option value="Sambanova">Sambanova</option>
            <option value="Rackmount">Rackmount</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Chassis Form Factor</Form.Label>
          <Form.Select
            name="chassis_form_factor"
            value={formData.chassis_form_factor}
            onChange={handleChange}
          >
            <option value="">Select Chassis Form Factor</option>
            <option value="1U">1U</option>
            <option value="2U">2U</option>
            <option value="4U">4U</option>
            <option value="8U">8U</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Dimensions (in inches)</Form.Label>
          <Row>
            <Col>
              <Form.Control
                type="number"
                step="0.01"
                name="length"
                value={formData.dimensions.length}
                onChange={handleChange}
                placeholder="Length"
              />
            </Col>
            <Col>
              <Form.Control
                type="number"
                step="0.01"
                name="width"
                value={formData.dimensions.width}
                onChange={handleChange}
                placeholder="Width"
              />
            </Col>
            <Col>
              <Form.Control
                type="number"
                step="0.01"
                name="height"
                value={formData.dimensions.height}
                onChange={handleChange}
                placeholder="Height"
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className={styles.formGroup}>
          <Form.Label>Net Weight (kg)</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            name="net_weight"
            value={formData.net_weight}
            onChange={handleChange}
            placeholder="Enter net weight"
          />
        </Form.Group>

            <Form.Group className={styles.formGroup}>
          <Form.Label>Gross Weight (kg)</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            name="gross_weight"
            value={formData.gross_weight}
            onChange={handleChange}
            placeholder="Enter gross weight"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>System Cooling</Form.Label>
          <Form.Control
            type="text"
            name="system_cooling"
            value={formData.system_cooling}
            onChange={handleChange}
            placeholder="Enter system cooling details"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>CPU-GPU Interconnect</Form.Label>
          <Form.Control
            type="text"
            name="cpu_gpu_interconnect"
            value={formData.cpu_gpu_interconnect}
            onChange={handleChange}
            placeholder="Enter CPU-GPU interconnect details"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>GPU-GPU Interconnect</Form.Label>
          <Form.Control
            type="text"
            name="gpu_gpu_interconnect"
            value={formData.gpu_gpu_interconnect}
            onChange={handleChange}
            placeholder="Enter GPU-GPU interconnect details"
          />
        </Form.Group>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Additional Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="additional_notes"
            value={formData.additional_notes}
            onChange={handleChange}
            placeholder="Enter any additional notes or comments"
          />
        </Form.Group>

        <div className={styles.buttonContainer}>
          <Button variant="primary" type="submit">
            Update Listing
          </Button>
          <Button variant="secondary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EditListing;