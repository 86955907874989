import React, { useState, useEffect } from 'react';
import { Container, Table, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles/UserCard.module.css';
import axiosInstance from './config/axios';

const Buyers = () => {
  const [buyers, setBuyers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBuyers = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('access');
        if (!token) {
          navigate('/login');
          return;
        }

        const { data } = await axiosInstance.get('/api/users/?purpose=buyer');
        
        const filteredBuyers = data.filter(user => 
          user && !user.is_superuser && user.purpose === 'buyer'
        );
        setBuyers(filteredBuyers);
      } catch (err) {
        console.error('API Error:', err);
        if (err.response) {
          // Handle specific HTTP error responses
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.removeItem('access');
            navigate('/login');
          }
          setError(err.response.data.message || 'Failed to load buyers. Please try again later.');
        } else if (err.request) {
          // Handle network errors
          setError('Network error. Please check your connection.');
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBuyers();
  }, [navigate]);

  const handleDelete = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this buyer?')) {
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem('access');
      if (!token) {
        navigate('/login');
        return;
      }

      await axiosInstance.delete(`/api/users/delete/${userId}/`);
      setBuyers(current => current.filter(buyer => buyer.id !== userId));
    } catch (err) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem('access');
        navigate('/login');
      }
      setError('Failed to delete buyer. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={`${styles.container} flex justify-center items-center min-h-[200px]`}>
        <div className={`${styles.spinner} animate-spin h-8 w-8 border-4 border-t-transparent rounded-full`}></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Registered Buyers</h2>
      
      {error && (
        <div className={styles.error}>
          {error}
        </div>
      )}

      <div className={styles.cardGrid}>
        {buyers.length > 0 ? (
          buyers.map((buyer) => (
            <div key={buyer.id} className={styles.card}>
              <div className={styles.cardBody}>
                <div className="space-y-3">
                  <div>
                    <span className={styles.label}>Username:</span>
                    <span className={`${styles.value} ml-2`}>{buyer.username || 'N/A'}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Email:</span>
                    <span className={`${styles.value} ml-2`}>{buyer.email || 'N/A'}</span>
                  </div>
                  <div>
                    <span className={styles.label}>City:</span>
                    <span className={`${styles.value} ml-2`}>{buyer.city || 'N/A'}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Country:</span>
                    <span className={`${styles.value} ml-2`}>{buyer.country || 'N/A'}</span>
                  </div>
                </div>
              </div>
              <div className={styles.cardFooter}>
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDelete(buyer.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-300">No buyers found</div>
        )}
      </div>
    </div>
  );
};

export default Buyers;
