import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useListings } from './ListingsContext';
import styles from './styles/BidModal.module.css';
import axiosInstance from './config/axios';

const BidModal = ({ show, handleClose, listing, onBidPlaced }) => {
  const [bidAmount, setBidAmount] = useState('');
  const [message, setMessage] = useState({ type: '', content: '' });
  const [minBidAmount, setMinBidAmount] = useState(0);
  const [currentHighestBid, setCurrentHighestBid] = useState(0);
  const { updateListing } = useListings();

  useEffect(() => {
    if (listing) {
      setMinBidAmount(listing.ask_price / 2);
      setCurrentHighestBid(Number(listing.current_highest_bid) || 0);
      setBidAmount('');
    }
    setMessage({ type: '', content: '' });
  }, [listing, show]);

  const fetchCurrentBid = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get(
        `/api/gpu-listings/${listing.id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
      );
      const newHighestBid = Number(response.data.current_highest_bid) || 0;
      setCurrentHighestBid(newHighestBid);
      updateListing({...listing, current_highest_bid: newHighestBid, highest_bidder_username: response.data.highest_bidder_username});
    } catch (error) {
      console.error('Failed to fetch current bid:', error);
    }
  };

  useEffect(() => {
    let interval;
    if (show && listing) {
      fetchCurrentBid(); // Fetch immediately when modal opens
      interval = setInterval(fetchCurrentBid, 1000); // Fetch every second
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [show, listing]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage({ type: '', content: '' });
  
    if (!listing) {
      setMessage({ type: 'danger', content: 'No listing selected' });
      return;
    }

    const token = localStorage.getItem('access');
    if (!token) {
      setMessage({ type: 'danger', content: 'You must be logged in to place a bid' });
      return;
    }
  
    const bidAmountFloat = parseFloat(bidAmount);
    if (currentHighestBid > 0) {
      if (bidAmountFloat <= currentHighestBid) {
        setMessage({ type: 'danger', content: `Bid must be higher than the current highest bid ($${Number(currentHighestBid).toFixed(2)})` });
        return;
      }
    } else {
      if (bidAmountFloat < minBidAmount) {
        setMessage({ type: 'danger', content: `Bid must be at least $${minBidAmount.toFixed(2)}` });
        return;
      }
    }
  
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.post(
        `/api/gpu-listings/${listing.id}/place_bid/`,
        { amount: bidAmount },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data && response.status === 201) {
        setMessage({ type: 'success', content: 'Bid placed successfully!' });
        
        const updatedListing = { 
          ...listing, 
          current_highest_bid: bidAmount,
          highest_bidder_username: response.data.highest_bidder_username
        };
        updateListing(updatedListing);
        onBidPlaced(updatedListing);

        await fetchCurrentBid()
        
        setTimeout(() => {
          handleClose();
          setMessage({ type: '', content: '' }); // Clear the message when closing
        }, 2000);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Bid placement error:', error);
      if (error.response?.status === 400 && error.response?.data?.error) {
        setMessage({ type: 'danger', content: error.response.data.error });
      } else if (error.response?.status === 403) {
        setMessage({ type: 'danger', content: 'You are not authorized to place this bid.' });
      } else {
        setMessage({ type: 'danger', content: 'Failed to place bid. Please try again.' });
      }
      fetchCurrentBid();
    }
  };

  if (!listing) {
    return null;
  }

  const formatCurrency = (value) => {
    const numValue = Number(value);
    return isNaN(numValue) ? '0.00' : numValue.toFixed(2);
  };

  return (
    <Modal show={show} onHide={handleClose} className={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>Place a Bid</Modal.Title>
        <button className={styles.closeButton} onClick={handleClose}>×</button>
      </Modal.Header>
      
      <Modal.Body className={styles.modalBody}>
        <Form onSubmit={handleSubmit}>
          <div className={styles.infoRow}>
            <div className={styles.infoText}>
              Ask Price: ${formatCurrency(listing?.ask_price)}
            </div>
            {currentHighestBid === 0 ? (
              <div className={styles.infoText}>
                Minimum Bid: ${formatCurrency(minBidAmount)}
              </div>
            ) : (
              <div className={styles.currentBid}>
                Current Highest Bid: ${formatCurrency(currentHighestBid)}
              </div>
            )}
          </div>

          <Form.Group className={styles.formGroup}>
            <Form.Label className={styles.formLabel}>
              Your Bid Amount (USD)
            </Form.Label>
            <Form.Control
              className={styles.formControl}
              type="number"
              step="0.01"
              value={bidAmount}
              onChange={(e) => setBidAmount(e.target.value)}
              required
              min={currentHighestBid > 0 ? (currentHighestBid + 0.01) : minBidAmount}
            />
          </Form.Group>

          {message.content && (
            <Alert className={`${styles.alert} ${styles[message.type]}`}>
              {message.content}
            </Alert>
          )}

          <Button className={styles.submitButton} type="submit">
            Place Bid
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BidModal;