import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useListings } from './ListingsContext';
import styles from './styles/CreateGpuListing.module.css';
import axiosInstance from './config/axios';

const GPUListingForm = () => {
  const [formData, setFormData] = useState({
    server_name: '',
    state: '',
    country: '',
    ask_price: '',
    duration_of_bid: '',
    condition: '',
    warranty: '',
    gpu_type: '',
    number_of_nodes: 1,
    number_of_gpus_per_node: 1,
    minimum_order_quantity: 1,
    processor_type: '',
    memory: '',
    operating_systems: [],
    motherboard: '',
    chassis: '',
    chassis_form_factor: '',
    dimensions: { length: '', width: '', height: '' },
    net_weight: '',
    gross_weight: '',
    system_cooling: '',
    cpu_gpu_interconnect: '',
    gpu_gpu_interconnect: '',
    power_consumption: '',
    memory_type: '',
    configuration_type: '',
    additional_notes: '',
  });

  const [submitStatus, setSubmitStatus] = useState(null);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const navigate = useNavigate();
  const { fetchListings } = useListings();

  const requiredFields = [
    'server_name',
    'state',
    'country',
    'ask_price',
    'duration_of_bid',
    'condition',
    'gpu_type',
    'chassis',
    'chassis_form_factor'
  ];

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));

    if (name === 'length' || name === 'width' || name === 'height') {
      setFormData(prevState => ({
        ...prevState,
        dimensions: {
          ...prevState.dimensions,
          [name]: value === '' ? '' : Number(value)
        }
      }));
    } else if (type === 'number') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value === '' ? '' : Number(value)
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  const handleOSToggle = (option) => {
    setFormData(prevState => ({
      ...prevState,
      operating_systems: prevState.operating_systems.includes(option)
        ? prevState.operating_systems.filter(item => item !== option)
        : [...prevState.operating_systems, option]
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    requiredFields.forEach(field => {
      if (!formData[field] || formData[field] === '') {
        newErrors[field] = `${field.replace(/_/g, ' ').charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const prepareFormData = () => {
    const submitData = { ...formData };
    // Convert empty string values to null for optional fields
    Object.keys(submitData).forEach(key => {
      if (!requiredFields.includes(key) && submitData[key] === '') {
        submitData[key] = null;
      }
    });
    // Handle dimensions
    if (submitData.dimensions) {
      submitData.dimensions = [
        submitData.dimensions.length || null,
        submitData.dimensions.width || null,
        submitData.dimensions.height || null
      ];
    }
    // Convert empty arrays to null
    if (submitData.operating_systems && submitData.operating_systems.length === 0) {
      submitData.operating_systems = null;
    }
    return submitData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    setErrors({});
    const allTouched = {};
    requiredFields.forEach(field => {
        allTouched[field] = true;
    });
    setTouched(allTouched);
    
    if (!validateForm()) {
        setSubmitStatus({
            type: 'danger',
            message: 'Please fill in all required fields marked with *'
        });
        return;
    }
    
    const token = localStorage.getItem('access');
    if (!token) {
        setSubmitStatus({ 
            type: 'danger', 
            message: 'You must be logged in to create a listing.' 
        });
        return;
    }

    const submitData = prepareFormData();

    try {
        // Correct axios usage
        const response = await axiosInstance.post('/api/gpu-listings/', submitData);
        
        if (response.status === 201) {  // Check for successful creation
            await fetchListings(); // Fetch updated listings
            setSubmitStatus({ 
                type: 'success', 
                message: 'GPU Listing created successfully!' 
            });
            
            // Clear the form
            setFormData({
                server_name: '',
                state: '',
                country: '',
                ask_price: '',
                duration_of_bid: '',
                condition: '',
                warranty: '',
                gpu_type: '',
                number_of_nodes: 1,
                number_of_gpus_per_node: 1,
                minimum_order_quantity: 1,
                processor_type: '',
                memory: '',
                operating_systems: [],
                motherboard: '',
                chassis: '',
                chassis_form_factor: '',
                dimensions: { length: '', width: '', height: '' },
                net_weight: '',
                gross_weight: '',
                system_cooling: '',
                cpu_gpu_interconnect: '',
                gpu_gpu_interconnect: '',
                power_consumption: '',
                memory_type: '',
                configuration_type: '',
                additional_notes: '',
            });
            
            navigate('/dashboard');
        }
    } catch (error) {
        console.error('Error creating GPU listing:', error);
        if (error.response?.data) {
            console.error('Error response:', error.response.data);
            // Set field-specific errors
            setErrors(error.response.data);
        }
        setSubmitStatus({ 
            type: 'danger', 
            message: 'Failed to create GPU listing. Please check all required fields and try again.' 
        });
    }
  };

  const osOptions = [
    "Ubuntu (Ubuntu 22.04 LTS and Ubuntu 20.04)",
    "Debian (various versions including Debian 12, 11, 10, 9)",
    "Red Hat Enterprise Linux (RHEL) (versions 9, 8, 7)",
    "Rocky Linux (versions 9, 8)",
    "CentOS"
  ];

return (
    <Container className={styles.container}>
      {submitStatus && (
        <Alert className={`${styles.alert} ${styles[submitStatus.type]}`}>
          {submitStatus.message}
        </Alert>
      )}
      <h2 className={styles.formTitle}>Create GPU Listing</h2>
      <p className={styles.requiredFieldNote}>Fields marked with * are required</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className={styles.formGroup}>
          <Form.Label>Server Name*</Form.Label>
          <Form.Control
            className={`${styles.formControl} ${touched.server_name && errors.server_name ? styles.errorInput : ''}`}
            type="text"
            name="server_name"
            value={formData.server_name}
            onChange={handleChange}
            placeholder="Enter server name"
            required
          />
          {touched.server_name && errors.server_name && (
            <div className={styles.errorMessage}>{errors.server_name}</div>
          )}
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>State*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.state && errors.state ? styles.errorInput : ''}`}
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="Enter state"
                required
              />
              {touched.state && errors.state && (
                <div className={styles.errorMessage}>{errors.state}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Country*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.country && errors.country ? styles.errorInput : ''}`}
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Enter country"
                required
              />
               {touched.country && errors.country && (
                <div className={styles.errorMessage}>{errors.country}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Ask Price (USD)*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.ask_price && errors.ask_price ? styles.errorInput : ''}`}
                type="number"
                step="0.01"
                name="ask_price"
                value={formData.ask_price}
                onChange={handleChange}
                placeholder="Enter price"
                required
              />
              {touched.ask_price && errors.ask_price && (
                <div className={styles.errorMessage}>{errors.ask_price}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Duration of Bid (days)*: {formData.duration_of_bid}</Form.Label>
              <Form.Range
                className={`${styles.range} ${touched.duration_of_bid && errors.duration_of_bid ? styles.errorInput : ''}`}
                name="duration_of_bid"
                value={formData.duration_of_bid}
                onChange={handleChange}
                min="1"
                max="7"
                required
              />
              {touched.duration_of_bid && errors.duration_of_bid && (
                <div className={styles.errorMessage}>{errors.duration_of_bid}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Condition*</Form.Label>
              <Form.Select
                className={`${styles.select} ${touched.condition && errors.condition ? styles.errorInput : ''}`}
                name="condition"
                value={formData.condition}
                onChange={handleChange}
                required
              >
                <option value="">Select Condition</option>
                <option value="New">New</option>
                <option value="Used">Used</option>
              </Form.Select>
              {touched.condition && errors.condition && (
                <div className={styles.errorMessage}>{errors.condition}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>GPU Type*</Form.Label>
              <Form.Select
                className={`${styles.select} ${touched.gpu_type && errors.gpu_type ? styles.errorInput : ''}`}
                name="gpu_type"
                value={formData.gpu_type}
                onChange={handleChange}
                required
              >
                <option value="">Select GPU Type</option>
                <option value="NVIDIA Blackwell B100">NVIDIA Blackwell B100</option>
                <option value="NVIDIA Blackwell B200">NVIDIA Blackwell B200</option>
                <option value="NVIDIA H200 SXM">NVIDIA H200 SXM</option>
                <option value="NVIDIA H200 NVL">NVIDIA H200 NVL</option>
                <option value="NVIDIA H100 PCIe">NVIDIA H100 PCIe</option>
                <option value="NVIDIA H100 SXM">NVIDIA H100 SXM</option>
                <option value="NVIDIA H100 NVL">NVIDIA H100 NVL</option>
                <option value="NVIDIA A100 SXM">NVIDIA A100 SXM</option>
                <option value="NVIDIA A100 PCIe">NVIDIA A100 PCIe</option>
                <option value="NVIDIA L40S">NVIDIA L40S</option>
                <option value="NVIDIA L40">NVIDIA L40</option>
                <option value="NVIDIA A40">NVIDIA A40</option>
                <option value="NVIDIA A6000">NVIDIA A6000</option>
                <option value="AMD Instinct MI325x">AMD Instinct MI325x</option>
                <option value="AMD Instinct MI300x">AMD Instinct MI300x</option>
                <option value="AMD Instinct MI300A APU">AMD Instinct MI300A APU</option>
                <option value="AMD Instinct MI250X">AMD Instinct MI250X</option>
                <option value="AMD Instinct MI250">AMD Instinct MI250</option>
                <option value="GROQ LPU">GROQ LPU</option>
                <option value="CEREBRAS Wafer Scale Engine">CEREBRAS Wafer Scale Engine</option>
                <option value="SAMBANOVA SN40L RDU">SAMBANOVA SN40L RDU</option>
                <option value="ETCHED AI Sohu">ETCHED AI Sohu</option>
              </Form.Select>
              {touched.gpu_type && errors.gpu_type && (
                <div className={styles.errorMessage}>{errors.gpu_type}</div>
              )}
            </Form.Group>
          </Col>
        </Row>
        
        <Row>
        <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Warranty (years) : {formData.warranty}</Form.Label>
              <Form.Range
                className={styles.range}
                name="warranty"
                value={formData.warranty}
                onChange={handleChange}
                min="0"
                max="7"
              />
          </Form.Group>
          </Col>
          <Col md={6}>
          <Form.Group className={styles.formGroup}>
            <Form.Label>Chassis Form Factor*</Form.Label>
            <Form.Select
              className={`${styles.select} ${touched.chassis_form_factor && errors.chassis_form_factor ? styles.errorInput : ''}`}
              name="chassis_form_factor"
              value={formData.chassis_form_factor}
              onChange={handleChange}
              required
            >
              <option value="">Select Chassis Form Factor</option>
              <option value="1U">1U</option>
              <option value="2U">2U</option>
              <option value="4U">4U</option>
              <option value="8U">8U</option>
            </Form.Select>
            {touched.chassis_form_factor && errors.chassis_form_factor && (
              <div className={styles.errorMessage}>{errors.chassis_form_factor}</div>
            )}
          </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Number of Nodes*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.number_of_nodes && errors.number_of_nodes ? styles.errorInput : ''}`}
                type="number"
                name="number_of_nodes"
                value={formData.number_of_nodes}
                onChange={handleChange}
                min="1"
                required
              />
              {touched.number_of_nodes && errors.number_of_nodes && (
                <div className={styles.errorMessage}>{errors.number_of_nodes}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>GPUs per Node*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.number_of_gpus_per_node && errors.number_of_gpus_per_node ? styles.errorInput : ''}`}
                type="number"
                name="number_of_gpus_per_node"
                value={formData.number_of_gpus_per_node}
                onChange={handleChange}
                min="1"
                required
              />
              {touched.number_of_gpus_per_node && errors.number_of_gpus_per_node && (
                <div className={styles.errorMessage}>{errors.number_of_gpus_per_node}</div>
              )}
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Minimum Order Quantity*</Form.Label>
              <Form.Control
                className={`${styles.formControl} ${touched.minimum_order_quantity && errors.minimum_order_quantity ? styles.errorInput : ''}`}
                type="number"
                name="minimum_order_quantity"
                value={formData.minimum_order_quantity}
                onChange={handleChange}
                min="1"
                required
              />
              {touched.minimum_order_quantity && errors.minimum_order_quantity && (
                <div className={styles.errorMessage}>{errors.minimum_order_quantity}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Processor (CPU) Type</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="text"
                name="processor_type"
                value={formData.processor_type}
                onChange={handleChange}
                placeholder="Enter CPU type"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Memory (GB)</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="number"
                name="memory"
                value={formData.memory}
                onChange={handleChange}
                placeholder="Enter memory in GB"
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Operating Systems</Form.Label>
          <Dropdown>
            <Dropdown.Toggle className={styles.dropdownToggle}>
              Select Operating Systems ({formData.operating_systems.length} selected)
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              {osOptions.map((option, index) => (
                <Dropdown.Item 
                  key={index}
                  className={styles.dropdownItem}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOSToggle(option);
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    label={option}
                    checked={formData.operating_systems.includes(option)}
                    onChange={() => {}}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Motherboard</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="text"
                name="motherboard"
                value={formData.motherboard}
                onChange={handleChange}
                placeholder="Enter motherboard details"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Chassis*</Form.Label>
              <Form.Select
                className={`${styles.select} ${touched.chassis && errors.chassis ? styles.errorInput : ''}`}
                name="chassis"
                value={formData.chassis}
                onChange={handleChange}
                required
              >
                <option value="">Select Chassis</option>
                <option value="Dell">Dell</option>
                <option value="HPE">HPE</option>
                <option value="ASUS">ASUS</option>
                <option value="NVIDIA">NVIDIA</option>
                <option value="Lenovo">Lenovo</option>
                <option value="Supermicro">Supermicro</option>
                <option value="IBM">IBM</option>
                <option value="Inspur">Inspur</option>
                <option value="GIGABYTE">GIGABYTE</option>
                <option value="Atos">Atos</option>
                <option value="Penguin Computing">Penguin Computing</option>
                <option value="QCT">QCT (Quanta Cloud Technology)</option>
                <option value="DFI">DFI</option>
                <option value="Tyan">Tyan</option>
                <option value="GROQ">GROQ</option>
                <option value="Cerebras">Cerebras</option>
                <option value="Etched">Etched</option>
                <option value="Sambanova">Sambanova</option>
                <option value="Rackmount">Rackmount</option>
              </Form.Select>
              {touched.chassis && errors.chassis && (
                <div className={styles.errorMessage}>{errors.chassis}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

       

        <Form.Group className={styles.formGroup}>
          <Form.Label>Dimensions (inches)</Form.Label>
          <Row>
            <Col md={4}>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="0.01"
                name="length"
                value={formData.dimensions.length}
                onChange={handleChange}
                placeholder="Length"
              />
            </Col>
            <Col md={4}>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="0.01"
                name="width"
                value={formData.dimensions.width}
                onChange={handleChange}
                placeholder="Width"
              />
            </Col>
            <Col md={4}>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="0.01"
                name="height"
                value={formData.dimensions.height}
                onChange={handleChange}
                placeholder="Height"
              />
            </Col>
          </Row>
        </Form.Group>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Net Weight (kg)</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="0.01"
                name="net_weight"
                value={formData.net_weight}
                onChange={handleChange}
                placeholder="Enter net weight"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Gross Weight (kg)</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="number"
                step="0.01"
                name="gross_weight"
                value={formData.gross_weight}
                onChange={handleChange}
                placeholder="Enter gross weight"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>System Cooling</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="text"
                name="system_cooling"
                value={formData.system_cooling}
              onChange={handleChange}
              placeholder="Enter system cooling details"
            />
             
            </Form.Group>
          </Col>
        
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Power Consumption (W)</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="number"
                name="power_consumption"
                value={formData.power_consumption}
                onChange={handleChange}
                placeholder="Enter power consumption"
              />
               
            </Form.Group>
          </Col>
        </Row>
        
        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Memory Type</Form.Label>
              <Form.Select
                className={styles.select}
                name="memory_type"
                value={formData.memory_type}
                onChange={handleChange}
              >
                <option value="">Select Memory Type</option>
                <option value="HBM2">HBM2</option>
                <option value="HBM2e">HBM2e</option>
                <option value="HBM3">HBM3</option>
                <option value="Other">Other</option>
              </Form.Select>
              
            </Form.Group>
          </Col>
          
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>Configuration Type</Form.Label>
              <Form.Select
                className={styles.select}
                name="configuration_type"
                value={formData.configuration_type}
                onChange={handleChange}
              >
                <option value="">Select Configuration Type</option>
                <option value="Server">Server</option>
                <option value="Baseboard">Baseboard</option>
                <option value="Other">Other</option>
              </Form.Select>
               
            </Form.Group>
          </Col>
          

        </Row>
        <Row>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>CPU-GPU Interconnect</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="text"
                name="cpu_gpu_interconnect"
                value={formData.cpu_gpu_interconnect}
                onChange={handleChange}
                placeholder="Enter CPU-GPU interconnect details"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className={styles.formGroup}>
              <Form.Label>GPU-GPU Interconnect</Form.Label>
              <Form.Control
                className={styles.formControl}
                type="text"
                name="gpu_gpu_interconnect"
                value={formData.gpu_gpu_interconnect}
                onChange={handleChange}
                placeholder="Enter GPU-GPU interconnect details"
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className={styles.formGroup}>
          <Form.Label>Additional Notes</Form.Label>
          <Form.Control
            className={styles.textarea}
            as="textarea"
            rows={3}
            name="additional_notes"
            value={formData.additional_notes}
            onChange={handleChange}
            placeholder="Enter any additional notes or comments"
          />
        </Form.Group>

        <Button className={styles.submitButton} type="submit">
          Submit Listing
        </Button>
      </Form>
    </Container>
  );
};

export default GPUListingForm;