import React from 'react';
import countryList from 'react-select-country-list';

const CountrySelect = ({ value, onChange }) => {
  const options = React.useMemo(() => countryList().getData(), []);

  return (
    <select value={value} onChange={e => onChange(e.target.value)}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;