import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './styles/Auth.module.css';
import OTPVerification from './OtpVerification';
import axiosInstance from './config/axios';

const Login = ({ setIsLoggedIn, setUsername, setUserId }) => {  
  const [formData, setFormData] = useState({ login: '', password: '' }); 
  const [error, setError] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await axiosInstance.post('/api/login/', formData);
      
      if (response.data.require_verification) {
        setPhoneNumber(response.data.phone_number);
        setShowOTP(true);
      } else {
        handleLoginSuccess(response.data);
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      setError(error.response?.data?.message || 'Invalid credentials. Please try again.');
    }
  };

  const handleLoginSuccess = (data) => {
    // Save all necessary authentication data to localStorage
    localStorage.setItem('access', data.access);
    localStorage.setItem('username', data.user.username);
    localStorage.setItem('userId', data.user.id);
    
    // Update app state
    setIsLoggedIn(true);
    setUsername(data.user.username);
    setUserId(data.user.id);
    
    navigate('/dashboard');
  };

  const handleVerificationSuccess = (data) => {
    handleLoginSuccess(data);
  };

  const handleVerificationCancel = () => {
    setShowOTP(false);
    setPhoneNumber('');
    setFormData({ login: '', password: '' });
  };

  if (showOTP) {
    return (
      <OTPVerification 
        phoneNumber={phoneNumber}
        onVerificationSuccess={handleVerificationSuccess}
        onCancel={handleVerificationCancel}
      />
    );
  }

  return (
    <div className={styles.authContainer}>
      <h2 className={styles.title}>Login</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>Username or Email</label>
          <input
            type="text"
            name="login"
            value={formData.login}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            className={styles.input}
          />
        </div>
        <button type="submit" className={styles.button}>Login</button>
        <p className={styles.message}>
          Don't have an account? <a href="/register" className={styles.link}>Register</a>
        </p>
      </form>
    </div>
  );
};

export default Login;