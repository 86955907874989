import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container, Alert } from 'react-bootstrap';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Detail.module.css';
import axiosInstance from './config/axios';

const DetailRow = ({ label, value }) => (
  <div className={styles.detailRow}>
    <div className={styles.label}>{label}:</div>
    <div className={styles.value}>{value || 'Not Provided'}</div>
  </div>
);

const DetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [showBidModal, setShowBidModal] = useState(false);
  const [error, setError] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const { updateListing } = useListings();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('access');
        const response = await axiosInstance.get('/api/user-details/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        console.log('User details:', response.data);
        setUserDetails(response.data);
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
    fetchListing();
    
    let interval;
    if (!showBidModal) {
      interval = setInterval(fetchListing, 5000);
    }
    return () => clearInterval(interval);
  }, [id, showBidModal]);

  const fetchListing = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get(`/api/gpu-listings/${id}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setListing(response.data);
    } catch (error) {
      console.error('Error fetching listing:', error);
      setError('Failed to fetch listing details. Please try again.');
    }
  };

  const handleBidClick = () => {
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
  };

  const handleBidPlaced = (updatedListing) => {
    updateListing(updatedListing);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this listing?')) {
      try {
        const token = localStorage.getItem('access');
        await axiosInstance.delete(`/api/gpu-listings/${id}/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        navigate('/dashboard');
      } catch (error) {
        console.error('Error deleting listing:', error);
        setError('Failed to delete listing');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/edit-listing/${id}`);
  };

  if (error) {
    return <Container className={styles.container}><Alert variant="danger">{error}</Alert></Container>;
  }

  if (!listing) {
    return <Container className={styles.container}>Loading...</Container>;
  }

  return (
    <Container className={styles.container}>
      <div className={styles.header}>
        <Button 
          className={styles.backButton}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {userDetails && userDetails.is_superuser && (
          <div className={styles.buttonContainer}>
            <Button 
              className={styles.editButton}
              onClick={handleEdit}
            >
              Edit Listing
            </Button>
            <Button 
              className={styles.deleteButton}
              onClick={handleDelete}
            >
              Delete Listing
            </Button>
          </div>
        )}
      </div>

      <h2 className={styles.title}>{listing.gpu_type} Details</h2>

      <div className={styles.detailsContainer}>
        <DetailRow label="Server Name" value={listing.server_name} />
        <DetailRow label="Location" value={`${listing.state}, ${listing.country}`} />
        <DetailRow label="Condition" value={listing.condition} />
        <DetailRow label="Warranty" value={listing.warranty ? `${listing.warranty} years` : 'Not Provided'} />
        <DetailRow label="Ask Price" value={`$${listing.ask_price}`} />
        <DetailRow 
          label="Current Highest Bid" 
          value={
            listing.current_highest_bid 
              ? `$${listing.current_highest_bid}${listing.highest_bidder_username ? ` by ${listing.highest_bidder_username}` : ''}`
              : 'No bids yet'
          } 
        />
        <DetailRow label="Time Left" value={listing.time_left} />
        <DetailRow label="Number of Nodes" value={listing.number_of_nodes} />
        <DetailRow label="GPUs per Node" value={listing.number_of_gpus_per_node} />
        <DetailRow label="Minimum Order" value={listing.minimum_order_quantity} />
        <DetailRow label="Processor Type" value={listing.processor_type} />
        <DetailRow label="Memory" value={`${listing.memory} GB`} />
        <DetailRow label="Operating Systems" value={listing.operating_systems?.join(', ')} />
        <DetailRow label="Motherboard" value={listing.motherboard} />
        <DetailRow label="Chassis" value={listing.chassis} />
        <DetailRow label="Chassis Form Factor" value={listing.chassis_form_factor} />
        <DetailRow label="Dimensions" value={listing.dimensions?.join(' x ')} />
        <DetailRow label="Net Weight" value={listing.net_weight ? `${listing.net_weight} kg` : null} />
        <DetailRow label="Gross Weight" value={listing.gross_weight ? `${listing.gross_weight} kg` : null} />
        <DetailRow label="System Cooling" value={listing.system_cooling} />
        <DetailRow label="Power Consumption" value={`${listing.power_consumption} W`} />
        <DetailRow label="Memory Type" value={listing.memory_type ? listing.memory_type : 'Not Provided'} />
        <DetailRow label="Configuration Type" value={listing.configuration_type ? listing.configuration_type : 'Not Provided'} />
        <DetailRow label="CPU-GPU Interconnect" value={listing.cpu_gpu_interconnect} />
        <DetailRow label="GPU-GPU Interconnect" value={listing.gpu_gpu_interconnect} />
        <DetailRow label="Additional Notes" value={listing.additional_notes} />
      </div>

      <div className={styles.buttonContainer}>
        <Button 
          className={styles.bidButton}
          onClick={handleBidClick}
          disabled={listing.time_left === "Bidding ended"}
          size="lg"
        >
          Place Bid
        </Button>
      </div>

      <BidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={listing}
        onBidPlaced={handleBidPlaced}
      />
    </Container>
  );
};

export default DetailPage;