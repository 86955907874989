import axios from 'axios';

const isDevelopment = false; //Hardcoded to false for debugging production
console.log(isDevelopment);

const baseURL = isDevelopment 
  ? 'http://localhost:8000'
  : process.env.REACT_APP_API_URL; 
console.log(baseURL);

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true 
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;