// Home.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import sampleImage from './assets/sample.jpg';
import logoImage from './assets/logo.jpg';
import logo1 from './assets/amd.jpg';
import logo2 from './assets/nvidia.jpg';
import logo3 from './assets/hpe.jpg';
import logo4 from './assets/dell.jpg';
import logo5 from './assets/supermicro.jpg';
import './styles/Home.css';

const Home = () => {
  const navigate = useNavigate();
  
  const handleGetStarted = () => {
    navigate('/register');
  };

  return (
    <div className="home-container">
      <nav className="custom-navbar">
        <div className="nav-brand">
          <img src={logoImage} alt="BitOoda Logo" className="brand-logo" />
          <span className="brand-name">HPC Marketplace</span>
        </div>
        <div className="nav-buttons">
          <Link to="/register" className="signup-btn">Sign up →</Link>
          <Link to="/login" className="login-btn">Log In <span className="login-arrow">→</span></Link>
        </div>
      </nav>

      <Container>
        <div className="hero-section" style={{ marginTop: '60px' }}>
          <div className="hero-content">
            <h1 className="marketplace-title" style={{ fontSize: '5rem', marginTop: '1rem' }}>BitOoda GPU Server Marketplace</h1>
            <h2 className="subtitle">Your Go To Place For Fully-Transparent<br />HPC Brokerage Services</h2>
            
            <div className="button-container">
              <Button onClick={handleGetStarted} className="get-started-btn">Get started →</Button>
            </div>
          </div>
        </div>

        <div className="scroll-content">
          <div className="content-wrapper">
            <h3 className="servers-title">Available Servers</h3>
            
            <div className="partners-section">
              <div className="marquee-container">
                <div className="marquee">
                  <div className="marquee-content">
                    <img src={logo1} alt="Server Partner" className="partner-logo" />
                    <img src={logo2} alt="Server Partner" className="partner-logo" />
                    <img src={logo3} alt="Server Partner" className="partner-logo" />
                    <img src={logo4} alt="Server Partner" className="partner-logo" />
                    <img src={logo5} alt="Server Partner" className="partner-logo" />
                    <img src={logo1} alt="Server Partner" className="partner-logo" />
                    <img src={logo2} alt="Server Partner" className="partner-logo" />
                    <img src={logo3} alt="Server Partner" className="partner-logo" />
                    <img src={logo4} alt="Server Partner" className="partner-logo" />
                    <img src={logo5} alt="Server Partner" className="partner-logo" />
                  </div>
                  <div className="marquee-content">
                    <img src={logo1} alt="Server Partner" className="partner-logo" />
                    <img src={logo2} alt="Server Partner" className="partner-logo" />
                    <img src={logo3} alt="Server Partner" className="partner-logo" />
                    <img src={logo4} alt="Server Partner" className="partner-logo" />
                    <img src={logo5} alt="Server Partner" className="partner-logo" />
                    <img src={logo1} alt="Server Partner" className="partner-logo" />
                    <img src={logo2} alt="Server Partner" className="partner-logo" />
                    <img src={logo3} alt="Server Partner" className="partner-logo" />
                    <img src={logo4} alt="Server Partner" className="partner-logo" />
                    <img src={logo5} alt="Server Partner" className="partner-logo" />
                  </div>
                </div>
              </div>
            </div>

            <div className="tagline-section">
              <p className="tagline gradient-text">
              Experience the future of GPU trading with our Compliant, Transparent, and Cutting-Edge Real-Time Marketplace
              </p>
            </div>

            <div className="preview-section">
              <div className="sample-image">
                <img src={sampleImage} alt="Marketplace Interface" />
              </div>
            </div>
          </div>

          <div className="button-container" style={{ textAlign: 'center', margin: '4rem auto' }}>
            <Button onClick={handleGetStarted} className="gradient-button">Get Started →</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Home;