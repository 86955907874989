import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ListingCard from './ListingCard';
import BidModal from './BidModal';
import { useListings } from './ListingsContext';
import styles from './styles/Dashboard.module.css';
import axiosInstance from './config/axios';

const Dashboard = ({ username, handleLogout, isLoggedIn, setUserDetails }) => {
  const [userDetails, setLocalUserDetails] = useState(null);
  const { listings, updateListings, updateListing } = useListings();
  const [error, setError] = useState('');
  const [showBidModal, setShowBidModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const navigate = useNavigate();
  
  const resetListings = async () => {
    await fetchListings();
  };

  const fetchListings = async () => {
    try {
      const token = localStorage.getItem('access');
      
      if (!token) {
        setError('Authentication token not found. Please log in again.');
        return;
      }

      const response = await axiosInstance.get('/api/gpu-listings/', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: function (status) {
          return status < 500;
        }
      });

      if (response.status === 401) {
        setError('Session expired. Please log in again.');
        return;
      }

      if (response.status !== 200) {
        throw new Error(`Server responded with status code ${response.status}`);
      }

      const activeListings = response.data.filter(listing => {
        const endTime = new Date(listing.bid_end_time);
        return endTime > new Date();
      });
      setError('');
      
      updateListings(activeListings);

    } catch (err) {
      console.error('Error fetching listings:', err);
      setError('Failed to fetch GPU listings. Please check your connection and try again.');
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('access');
        const response = await axiosInstance.get('/api/user-details/', {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        setLocalUserDetails(response.data);
        setUserDetails(response.data)
      } catch (err) {
        console.error('Error fetching user details:', err);
      }
    };

    fetchUserDetails();
    fetchListings();

    const interval = setInterval(fetchListings, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleCreateListing = () => {
    navigate('/create-listing');
  };

  const handleListingCreated = () => {
    fetchListings();
  };


  const handleRetry = () => {
    setError('');
    fetchListings();
  };

  const handleBidClick = (listing) => {
    setSelectedListing(listing);
    setShowBidModal(true);
  };

  const handleBidModalClose = () => {
    setShowBidModal(false);
    setSelectedListing(null);
  };

  const handleBidPlaced = (updatedListing) => {
    updateListing(updatedListing);
  };

  return (
    <Container className="py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Welcome {username}</h1>
        <div>
          {(userDetails?.purpose === 'seller' || userDetails?.is_superuser) && (
            <button 
              className="btn btn-primary me-2"
              onClick={handleCreateListing}
            >
              Create New Listing
            </button>
          )}
        </div>
      </div>

      {error && (
        <Alert variant="danger" className="mb-4">
          {error}
          <div className="mt-2">
            {error.includes('Please log in again') ? (
              <button 
                className="btn btn-outline-primary btn-sm"
                onClick={handleLogout}
              >
                Log In
              </button>
            ) : (
              <button 
                className="btn btn-outline-primary btn-sm"
                onClick={handleRetry}
              >
                Retry
              </button>
            )}
          </div>
        </Alert>
      )}

      <Row xs={1} md={2} lg={3} className="g-4">
        {listings.map((listing) => (
          <Col key={listing.id}>
            <ListingCard 
              listing={listing}
              onBidClick={handleBidClick}
            />
          </Col>
        ))}
      </Row>
      <BidModal
        show={showBidModal}
        handleClose={handleBidModalClose}
        listing={selectedListing}
        onBidPlaced={handleBidPlaced}
      />
    </Container>
  );
};

export default Dashboard;