import React, { createContext, useState, useContext } from 'react';
import axiosInstance from './config/axios';
const ListingsContext = createContext();

export const useListings = () => useContext(ListingsContext);

export const ListingsProvider = ({ children }) => {
  const [listings, setListings] = useState([]);

  const filterActiveListings = (listingsData) => {
    const now = new Date();
    return listingsData.filter(listing => {
      const endTime = new Date(listing.bid_end_time);
      return endTime > now;
    });
  };

  const updateListings = (newListings) => {
    // Only update with active listings
    const activeListings = filterActiveListings(newListings);
    setListings(activeListings);
  };

  const updateListing = (updatedListing) => {
    setListings(prevListings => 
      prevListings.map(listing => 
        listing.id === updatedListing.id ? updatedListing : listing
      )
    );
  };

  const fetchListings = async () => {
    try {
      const token = localStorage.getItem('access');
      const response = await axiosInstance.get('/api/gpu-listings/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = response.data;
      // Filter out expired listings before setting state
      const activeListings = filterActiveListings(data);
      setListings(activeListings);
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  return (
    <ListingsContext.Provider value={{ listings, updateListings, updateListing, fetchListings }}>
      {children}
    </ListingsContext.Provider>
  );
};

export default ListingsContext;